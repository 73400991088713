
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        























































































































@import "~@/scss/dashboard";

.progress-bar {
  width: 100%;
  height: 6px;

  background-color: $light;
  border-radius: 10px;

  &_line {
    height: 100%;
    border-radius: 5px
  }
}

.incidence-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  width: 25%;

  @media screen and (max-width: 550px) {
    width: 50%;
  }
}
