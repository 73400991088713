
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        







































































@import "~@/scss/dashboard";

.rating {
  height: calc(100% - 44px);
  overflow: auto;

  .rating-item {
    //$indent: 5px;

    display: flex;
    align-items: center;

    height: calc(14.285% - 5px);

    padding: 12px 16px;
    margin-bottom: 5px;

    border: 1px solid #E9E9E9;
    border-radius: .75rem;

    & img {
      $size: 24px;

      width: $size;
      height: $size;
      border-radius: 50%;
    }
  }
}
