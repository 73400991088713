
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




































































































































@import "~@/scss/dashboard";
@import "~@/scss/dashboardTable";

::v-deep {
  .b-table {
    position: relative;

    .table-wrapper {
      position: static;

      padding-bottom: 1px;
      max-height: 300px;
      overflow: auto !important;

      %absolutContent {
        content: "";
        position: absolute;
        top: 0;
        z-index: 1;

        width: 1px;
        height: 10px;
        background-color: white;
      }

      tfoot {
        position: static;
      }

      thead {
        tr {
          th {
            top: 0;

            &:first-child::before {
              @extend %absolutContent;
              left: 0;
            }

            &:last-child::before {
              @extend %absolutContent;
              right: 0;
            }
          }
        }
      }
    }
  }

}
