
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        





































































.fullGridColumns {
  grid-template-columns: 100% !important;
}
.statistics {
  $self: &;

  display: grid;
  grid-template-columns: 25% 75% 75%;

  #{ $self }-rating {
    grid-row: 1/3;
  }

  #{ $self }-wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-row: 2;
  }

  #{ $self }-capacity {
    display: flex;
    grid-column: 1/3
  }

  @media screen and (max-width: 1407px) {
    grid-template-columns: 45% 55% 75%;

    #{ $self }-wrapper, #{ $self }-rating  {
      grid-row: initial;
    }
    #{ $self }-wrapper  {
      grid-column: 3/1;
    }
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 100%;

    #{ $self }-wrapper, #{ $self }-rating, #{ $self }-capacity {
      grid-column: 1;
    }
    #{ $self }-wrapper, #{ $self }-capacity {
      display: block;

      &>.column {
        width: 100%;
      }
    }
  }
}
