
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        































































































































































































@import "~@/scss/dashboard";

.bonus-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 50%;

    @media screen and (max-width: 550px) {
        width: 100%;
    }
}

.progress-bar {
    width: 90px;
    height: 6px;

    margin: 0 5px;
    background-color: $light;
    border-radius: 5px;

    &_line {
        height: 100%;
        background-color: $sky-blue;
        border-radius: 5px
    }
}

.divider {
    height: 1px;
    background-color: $smoke;
    width: 100%;
    margin: $size-7 0;
}
